.appNavBar__background--color {
  background-color: #1A237E !important;
}

.appNavBar__button--dimentions {
  color: var(--color-white) !important;
  line-height: 18px !important;
}

.appNavBar__title--dimentions {
  color: var(--color-white) !important;
  font-size: 1.5rem !important;
  font-weight: bold !important;
  letter-spacing: 0 !important;
  line-height: 18px !important;
  padding-right: 0 !important;
}

.appNavBar__title--img {
  height: 24px !important;
  width: 93px !important;
  fill: var(--color-white) !important;
  color: var(--color-white) !important;
}

.appNavBar__secondary--img {
  height: 14px !important;
  width: 54px !important;
  fill: var(--color-white) !important;
  color: var(--color-white) !important;
}

.appNavBar__secondary--button {
  padding: 8px !important;
  height: 14px !important;
  width: 54px !important;
  margin-top: 17px !important;
  color: var(--color-white) !important;
  border-radius: 4px !important;
}

.appNavBar__secondaryFlex--button {
  padding: 8px !important;
  height: 14px !important;
  width: 54px !important;
  margin-top: 15px !important;
  color: var(--color-white) !important;
}

.appNavBar__grid--flex-end {
  width: 100% !important;
  display: flex;
  justify-content: flex-end !important;
}

.appNavBar__drawer-icon-margin {
  margin-left: -20px !important;
}

.appNavBar__login--icon--center {
  display: flex !important;
  justify-content: center !important;
}

.appNavBar__grid--flex-start {
  display: flex;
  justify-content: flex-start !important;
}

.appNavBar__flex--space-between {
  display: flex !important;
}

.appNavBar__icon--button--language {
  padding-left: 1vw;
  margin-right: 2vw;
}

.appNavBar__icon--language {
  margin-right: 0.5vw;
}

@media only screen and (min-width: 1280px) {
  .appNavBar__dimentions {
    line-height: 18px;
    width: 84% !important;
    height: var(--navbar-height);
    left: 0 !important;
    right: auto !important;
    z-index: 9000 !important;
    box-shadow: 0px 1px 0px 0px rgb(0 0 0 / 12%) !important;
  }
}

@media only screen and (max-width: 1279px) {
  .appNavBar__dimentions {
    width: 100% !important;
    height: var(--navbar-height);
    left: 0 !important;
    right: auto !important;
    z-index: 9000 !important;
    box-shadow: 0px 1px 0px 0px rgb(0 0 0 / 12%) !important;
  }
}

.appNavBar__width--user-menu {
  position: fixed;
  width: 16% !important;
  height: var(--navbar-height);
  margin-left: 84% !important;
  right: 0 !important;
  z-index: 9000 !important;
  display: flex;
  justify-content: left !important;
  align-items: center !important;
  padding-left: 0.5% !important;
  box-shadow: 0px 1px 0px 0px rgb(0 0 0 / 12%) !important;
}

.appNavBar__userInfo--container {
  margin-top: 1.23% !important;
  margin-left: 3.31% !important;
  margin-right: 9.07% !important;
  display: flex !important;
}

.appNavBar__userInfo--name--div {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  display: inline-block !important;
  width: 100% !important;
}

.appNavBar__userInfo--name {
  font-size: 0.875rem !important;
  letter-spacing: 0 !important;
  line-height: 16px !important;
  color: var(--color-white) !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  width: 75px !important;
  white-space: nowrap !important;
}

.appNavBar__userInfo--role--div {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  display: inline-block !important;
  width: 100% !important;
  white-space: nowrap !important;
  padding-left: 0px;
}

.appNavBar__userInfo--role {
  font-size: 0.813rem !important;
  font-weight: 900 !important;
  letter-spacing: 0 !important;
  line-height: 15px !important;
  color: var(--color-white) !important;
  min-width: 100px !important;
  white-space: nowrap !important;
}

.appNavBar__menu--language {
  z-index: 9999 !important;
  position: absolute !important;
}

.appNavBar__user--menu {
  color: var(--color-white) !important;
  width: 100%;
}

.appNavBar__user--menu--icon--button {
  padding-right: 2em !important;
}

.appNavBar__icon--user {
  color: var(--color-white) !important;
  height: 40px !important;
  width: 40px !important;
}

.appNavBar__image--user {
  color: var(--color-white) !important;
  height: 45px !important;
  width: 45px !important;
  border-radius: 50% !important;
  border: solid 1px !important;
}

.appNavBar__menu--user {
  z-index: 9999 !important;
  position: absolute !important;
}

.appNavBar__post--icon--color {
  background-color: #1A237E !important;
}

.appNavBar__post--margin {
  margin-left: 1rem;
}

.appNavBar__post--menu--max--width {
  max-width: 40%;
}

.appNavBar__post--word--wrap {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

.appNavBar__language--sup-padding {
  padding-bottom: 12px !important;
  font-weight: bold !important;
}

.appNavBar__user--container {
  background-color: var(--color-darken-green);
}

.appNavBar__dialog--flex {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.appNavBar__language--bold {
  font-weight: bold !important;
}

.appNavBar__button {
  font-family: "Source Sans Pro" !important;
  font-size: 0.938rem !important;
  font-weight: bold !important;
  letter-spacing: 0 !important;
}

.appNavBar__button:hover {
  background-color: unset !important;
  opacity: 0.66 !important;
}