.facets__div {
  width: 100%;
}

.facets__card--margin-bottom {
  margin-bottom: 25px !important;
}

.facets__sticky-top {
  z-index: unset;
  position: -webkit-sticky;
  position: sticky;
  top: 10px;
}

.facets__text--color {
  display: table !important;
  color: var(--color-black) !important;
  align-items: flex-start;
}

.facets__formControl--typography {
  margin-left: 0.9em !important;
  margin-top: 0.1em !important;
}

.facets__badge--typography {
  margin-top: 0.35em !important;
  margin-left: 0.3em !important;
}

.facets__nocontent--typography {
  margin-top: 1em !important;
  margin-left: 2em !important;
}

.facets__listItem {
  height: auto !important;
}

.facets__checkbox {
  display: table-cell !important;
}

.facets__badge {
  position: absolute !important;
  top: 0;
  margin-top: 0.5em !important;
  margin-left: 0.9em !important;
}

.facets__badge--selected {
  position: absolute !important;
  top: 0;
  margin-top: 0.3em !important;
  margin-left: 0.9em !important;
}

.facets__badge--button {
  width: 32px !important;
  height: 32px !important;
}

.facets__badge--button:hover {
  opacity: 0.8;
}

.facets__clear {
  display: table-cell !important;
  margin-left: 20px !important;
}

.facets__clear--button {
  color: var(--color-darken-green) !important;
}