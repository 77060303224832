.admin__repositories--card {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.admin__repositories--add--icon--color {
  color: var(--color-white) !important;
}

.repositories__singleTab--title {
  width: 300px;
  height: 67px;
  border-radius: 8px;
  background-color: var(--color-blue-table-header);
}

.repositories__singleTab--title-text {
  color: var(--color-white) !important;
  font-family: "Source Sans Pro";
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  padding-left: 34px;
  padding-top: 14px;
  text-transform: uppercase !important;
}

.repositories__singleTab--content {
  margin-top: -23px !important;
  margin-bottom: 46px !important;
  padding: 66px 45px !important;
  border-radius: 8px 0px 0px 0px !important;
}

.repositories__import--error {
  background-color: var(--color-lighten-red) !important;
}

.repositories__import--summary {
  flex-basis: 95%;
}

.repositories__importChip--error {
  background-color: var(--color-red) !important;
  color: var(--color-white) !important;
  border-radius: 20px !important;
  text-transform: none !important;
  padding-inline: 12px !important;
  height: 2.4em;
}

.repositories__importChip--success {
  background-color: var(--color-darken-green) !important;
  color: var(--color-white) !important;
  border-radius: 20px !important;
  text-transform: none !important;
  padding-inline: 12px !important;
  height: 2.4em;
  margin-right: 35px !important;
}