.log__container {
    padding-left: 30vw;
    padding-top: 6rem !important;
}

.log__grid--margin-bottom {
    margin-bottom: 2rem !important;
}
  
.log__grid--margin {
    margin: 22px !important;
    margin-inline: 5px !important;
}

.log__grid--margin-top {
    margin-top: 25px !important;
  }
  