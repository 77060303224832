.user__container {
  padding-top: 4rem !important;
  padding-bottom: 2rem !important;
}

.user__grid--margin-top {
  margin-top: 25px !important;
}

.user__grid--margin-bottom {
  margin-bottom: 2rem !important;
}

.user__grid--padding {
  padding: 22px !important;
  padding-inline: 5px !important;
}

.users__grid--margin {
  margin: 22px !important;
  margin-inline: 5px !important;
}