.admin__posts--card {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.admin__posts--add--icon--color {
  color: var(--color-white) !important;
}

.postForm__button--justify-right {
  margin-top: 1rem !important;
  margin-right: 7rem !important;
  display: grid !important;
  justify-content: right !important;
}

.post__grid--margin {
  margin: 22px !important;
  margin-inline: 5px !important;
}

.post__grid--margin-bottom {
  margin-bottom: 2rem !important;
}