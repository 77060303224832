.home__news--card {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 8px !important;
}

.news__card--media {
    height: 140px;
}

.news__card--media:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.news__card--media-bgcolor {
    height: 140px;
    background-color: var(--color-blue-nav) !important;
}

.news__card--actions {
    margin-top: 10px;
}

.news_card--typography {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.news__button--view {
    font-weight: 500 !important;
    font-size: 1rem !important;
    letter-spacing: 0 !important;
    border-radius: 20px !important;
    margin-left: 10px !important;
    margin-right: 18px !important;
    margin-bottom: 10px !important;
    text-transform: none !important;
    padding-inline: 23px !important;
    background-color: var(--color-darken-green) !important;
    color: var(--color-white) !important;
}

.news__subtitle--margin {
    margin-left: 22px !important;
    padding-right: 0.5em !important;
}

.news__date {
    font-size: 0.9em !important;
}

.news__card--image {
    max-height: 250px;
    object-fit: cover;
    width: 100% !important;
}

.news__date--margin {
    margin-top: 1em !important;
}

.news__cardAction {
    display: inline-block;
    position: relative;
    font-size: 200%;
    font-family: monospace;
    cursor: pointer;
    overflow: hidden;
    user-select: none;
    height: 100%;
}

.news__cardAction::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.1);
    width: 0;
    height: 0;
    margin-left: 0;
    margin-top: 0;
    border-radius: 100%;

    transition:
        width 500ms ease-in-out,
        height 500ms ease-in-out,
        margin-left 500ms ease-in-out,
        margin-top 500ms ease-in-out;
}

.news__cardAction:active::after {
    width: 1000px;
    height: 1000px;
    margin-left: -250px;
    margin-top: -250px;
}

.news__image--container {
    display: block;
    width: 100%;
    height: auto;
}

.news__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(0, 0, 0, 0.1);
}

.news__cardAction:hover .news__overlay {
    opacity: 1;
}