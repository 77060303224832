.configurations__container {
  padding-top: 4rem;
  padding-bottom: 4rem;
  margin-right: 6.16% !important;
  margin-left: 5.55% !important;
}

.configurations--template__margin__bottom {
  margin-bottom: 2rem;
}

.configuration--edit--icon--color{
  color: var(--color-white) !important;
}

.configuration__typography--textField {
  border: 1px solid var(--color-grey-outline) !important;
  border-radius: 3px !important;
}

.configuration__typography--textField--margin{
  margin-left: 1rem !important;
}