.tableComponent__width {
  width: 100%;
}

.text__color--green {
  color: var(--color-darken-green) !important;
}

.tableComponent--search-container {
  width: 100% !important;
  background-color: var(--color-darken-green) !important;
}

.tableComponent__search--margin {
  margin-top: 2rem;
  margin-bottom: 39px;
}

.tableComponent__search--button {
  display: flex !important;
  justify-content: center !important;
  min-width: 0px !important;

}

.tableComponent__lorem--text {
  margin-left: 14px !important;
  color: var(--color-grey-text) !important;
  font-family: "Source Sans Pro" !important;
  font-size: 1rem !important;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 26px !important;
}

.tableComponent__repository--filter {
  padding-block: 25px !important;
  padding-inline: 28px !important;
  border-radius: 8px !important;
  background-color: var(--color-lighten-blue);
  margin-bottom: 18px !important;
}

.tableComponent__search--inline {
  display: inline-block !important;
}

.selectComponent--input {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0px !important;
}

.textField__container--height {
  height: 40px !important;
}

.textField__container--table {
  background-color: white !important;
  border-radius: 3% !important;
}

.textField__divider--padding-left {
  padding-left: 4px !important;
}

.textField__divider--padding-right {
  padding-right: 4px !important;
}

.textField__typography {
  height: 100% !important;
  width: 100%;
  display: grid;
  align-content: center;
  font-size: 0.938rem !important;
  font-weight: 600 !important;
  color: var(--color-black) !important;
  letter-spacing: 0 !important;
  margin-top: 0.9em !important;
}

.textField__typography--form {
  display: flex;
  align-content: center;
  font-size: 0.938rem !important;
  font-weight: 500 !important;
  color: var(--color-black) !important;
  letter-spacing: 0 !important;
  margin-top: 0.9em !important;
  padding-left: 6px !important;
}

.textField__typography--preview {
  color: #999999;
  font-family: "Source Sans Pro";
  font-size: 0.813rem !important;
  letter-spacing: 0 !important;
  line-height: 16px !important;
  margin-left: 6px !important;
  margin-top: 9px !important;
}

.textField__typography--subtitle {
  font-family: Roboto !important;
  font-size: 1.5rem !important;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
  line-height: 28px !important;
  color: var(--color-black) !important;
  margin-top: 44px !important;
}

.textField__typography--subtitle-margin {
  font-family: Roboto !important;
  font-size: 1.5rem !important;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
  line-height: 28px !important;
  color: var(--color-black) !important;
  margin-top: 20px !important;
}

.textField__typography--subsubtitle {
  font-family: Roboto !important;
  font-size: 1.375rem !important;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
  line-height: 28px !important;
  color: var(--color-black) !important;
  margin-top: 22px !important;
}

.textField__typography--green {
  min-height: 46px !important;
  width: 100%;
  display: grid;
  align-content: center;
  font-size: 0.938rem !important;
  font-weight: 600 !important;
  color: var(--color-darken-green) !important;
  letter-spacing: 0 !important;
}

.request__content--typography {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: var(--color-grey-text) !important;
  font-family: "Source Sans Pro" !important;
  font-size: 0.938rem !important;
  letter-spacing: 0 !important;
  line-height: 19px !important;
}

.textField__multi--margin--top {
  margin-top: 0.5vh;
}

.radio__container {
  margin-bottom: 4px !important;
}

.radio__div--typography--margin--top {
  margin-top: 0.5rem;
}

.radio__div--margin--right {
  margin-right: 1rem;
}

.dialog__container {
  max-width: 75vw !important;
  max-height: 75vh !important;
}

.autoComplete__outline {
  border: 1px solid rgb(196, 194, 194);
}

.card__margin {
  margin: 4px !important;
}

.card__padding {
  padding: 4px !important;
}

.card__pie--chart--center {
  display: flex !important;
  justify-content: center !important;
}

.chart__margin--right {
  margin-right: 15px !important;
}

.container__padding {
  padding-top: 4rem;
  padding-bottom: 5rem;
}

.datePicker__container {
  margin-top: 4px !important;
  width: 100% !important;
}

.form__button--color-success {
  background-color: var(--color-darken-green) !important;
  color: var(--color-white) !important;
  font-weight: 500 !important;
  font-size: 1.125rem !important;
  letter-spacing: 0 !important;
  border-radius: 20px !important;
  margin-left: 10px !important;
  margin-right: 18px !important;
  text-transform: none !important;
  padding-inline: 23px !important;
}

.form__button--color-success:hover {
  opacity: 0.9 !important;
}

.form__button--color-error {
  height: 40px !important;
  min-width: 160px !important;
  background-color: var(--color-white) !important;
  color: var(--color-red) !important;
  font-weight: 500 !important;
  font-size: 1.125rem !important;
  letter-spacing: 0 !important;
  border-radius: 20px !important;
  border: 1px solid var(--color-red) !important;
  border-color: var(--color-red) !important;
  text-transform: none !important;
  padding-inline: 23px !important;
}

.form__one--button--margin-left {
  margin-left: 25px !important;
}

.form__button--color-error:hover {
  opacity: 0.9 !important;
}

.form__button--justify-left {
  display: flex !important;
  align-content: center !important;
  justify-content: left !important;
  margin-top: 1em !important;
}

.actionButton {
  font-family: "Source Sans Pro" !important;
  font-size: 1.125rem !important;
  letter-spacing: 0 !important;
  line-height: 21px !important;
  text-align: center !important;
  height: 40px !important;
  min-width: 160px !important;
}

.table__add--icon {
  color: #1A237E;
}

.admin__card--title {
  font-family: "Source Sans Pro" !important;
  font-size: 2.188rem !important;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
  line-height: 41px !important;
  margin-left: 12px !important;
}

.admin__card--subtitle {
  font-family: "Source Sans Pro" !important;
  font-size: 1.75rem !important;
  font-weight: 700 !important;
  letter-spacing: 0 !important;
  line-height: 41px !important;
}

.admin__form--title-margin {
  margin-bottom: 33px !important;
}

.admin__form--subtitle-margin {
  margin-top: 20px !important;
}

.admin__icon--create {
  color: var(--color-white) !important;
  font-family: "Source Sans Pro";
  font-size: 1.125rem;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
}

.admin__button--create {
  border-radius: 20px !important;
  width: 147px !important;
  color: var(--color-white) !important;
  font-family: "Source Sans Pro";
  font-size: 1.125rem;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
}

.admin__button--create:hover {
  opacity: 0.9 !important;
}

.admin__align--left {
  display: flex !important;
  align-content: flex-end !important;
  justify-content: flex-end !important;
  min-height: 43px !important;
}

.outline__search {
  background-color: var(--color-white) !important;
  color: var(--color-black) !important;
  height: 40px !important;
  font-size: 1rem !important;
  letter-spacing: 0 !important;
  line-height: 19px !important;
}

.arrowicon__color {
  fill: var(--color-blue-table-header) !important;
  color: var(--color-blue-table-header) !important;
}

.arrowicon__color--white {
  fill: var(--color-white) !important;
  color: var(--color-white) !important;
}

.fixed__heigth {
  height: 40px !important;
}

.table__row--content {
  height: 60px;
}

.table__cell--padding {
  padding-block: 0px !important;
}

.table__head--color {
  background-color: var(--color-blue-table-header) !important;
}

.table__body--color {
  background-color: var(--color-white) !important;
}

.table__head--border {
  border-radius: 8px !important;
}

.table__cell--color-white {
  color: var(--color-white) !important;
  font-weight: bold !important;
  font-size: 0.875rem !important;
  font-family: "Source Sans Pro" !important;
}

.fields__padding--left {
  padding-left: 3px !important;
}

.fields__padding--right {
  padding-right: 3px !important;
}

.fields__padding--right-grid {
  padding-right: 42px !important;
}

.form__flex--multiple {
  align-items: center !important;
  display: flex;
}

.form__align--right {
  display: flex !important;
  justify-content: flex-end !important;
}

.form__width--100 {
  width: 100% !important;
}

.form__align--space-between {
  display: flex !important;
  justify-content: space-between !important;
}

.form__button--add {
  margin-left: 19px !important;
  height: 40px !important;
  width: 55px !important;
  background-color: var(--color-darken-green) !important;
  border-radius: 20px !important;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.15) !important;
}

.form__button--add:hover {
  opacity: 0.9 !important;
}

.form__icon--add {
  background-color: var(--color-white) !important;
  color: var(--color-darken-green) !important;
  border-radius: 50% !important;
  font-size: small !important;
}

.form__button--remove {
  margin-left: 19px !important;
  height: 40px !important;
  width: 55px !important;
  background-color: var(--color-red) !important;
  border-radius: 20px !important;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.15) !important;
}

.form__button--remove:hover {
  opacity: 0.9 !important;
}

.form__icon--remove {
  background-color: var(--color-white) !important;
  color: var(--color-red) !important;
  border-radius: 50% !important;
  font-size: small !important;
}

.wyswyg--padding {
  width: 100%;
  border: 1px solid var(--color-grey-outline) !important;
}

td:first-child,
th:first-child {
  padding-left: 34px !important;
}

td:last-child,
th:last-child {
  padding-right: 34px !important;
}

.firstCell__weigth--width {
  max-width: 20vw;
}

.cell__max--width {
  max-width: 15vw;
}

.admin__container {
  padding-top: 4rem;
  padding-bottom: 4rem;
  margin-right: 6.16% !important;
  margin-left: 5.55% !important;
}

.admin__container-bottom {
  padding-bottom: 4rem;
  margin-right: 6.16% !important;
  margin-left: 5.55% !important;
}

.public__container--margin {
  margin-right: 14.53% !important;
  margin-left: 14.53% !important;
}

.public__container--padding {
  padding-top: 58px !important;
  padding-bottom: 153px !important;
}

.public__container--padding-bottom {
  padding-bottom: 153px !important;
}

.margin__top--9px {
  margin-top: 9px !important;
}

.margin__top--24px {
  margin-top: 24px !important;
}

.margin__bottom {
  margin-bottom: 3rem !important;
}

@media only screen and (min-width: 960px) {
  .width__50 {
    width: 50% !important;
  }

  .width__33 {
    width: 33% !important;
  }

  .width__10 {
    width: 10% !important
  }
}

@media only screen and (max-width: 960px) {
  .width__50 {
    width: 100% !important;
  }

  .width__33 {
    width: 100% !important;
  }

  .width__10 {
    width: 100% !important
  }
}

.checkbox__text {
  color: var(--color-grey-text) !important;
  margin-left: 6px !important;
}

.margin__top--10px {
  margin-top: 10px;
}

.indexes__cards {
  border-radius: 8px !important;
  margin-bottom: 11px !important;
  height: 175px !important;
  display: flex;
  align-items: center;
}

.indexes--cards__none {
  border-radius: 8px !important;
  margin-bottom: 11px !important;
  height: 175px !important;
  display: flex;
  align-items: center;
  justify-content: center !important;
}

.margin__left--87px {
  margin-left: 87px !important;
}

.margin__left--8px {
  margin-left: 8px !important;
}

.margin__right--12px {
  margin-right: 12px !important;
}

.indexes__div--index {
  display: flex !important;
  flex-direction: column;
  align-items: center;
}

.indexes__badge--size {
  width: 64px !important;
  height: 64px !important;
}

.indexes__number--text {
  color: var(--color-darken-green) !important;
  font-family: "Source Sans Pro" !important;
  font-size: 2.188rem !important;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
  line-height: 41px !important;
}

.indexes__label--text {
  color: var(--color-darken-green) !important;
  font-family: "Source Sans Pro" !important;
  font-size: 1.125rem !important;
  letter-spacing: 0 !important;
  line-height: 21px !important;
  text-align: center !important;
  width: 150px !important;
  flex-wrap: wrap !important;
}

.indexes__info--text {
  color: var(--color-grey-text) !important;
  font-family: "Source Sans Pro" !important;
  font-size: 1rem !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
}

.index__title {
  font-family: "Source Sans Pro" !important;
  font-size: 2.188rem !important;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
  line-height: 41px !important;
}

.about__container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.content--card {
  width: 90% !important;
  max-width: 907px !important;
}

.content_card--help {
  width: 70%;
}

@media (max-width:1279px) {
  .content_card--help {
    width: 100%;
  }
}

@media (min-width:1279px) {
  .content_card--help {
    width: 70%;
  }
}

@media (min-width:1928px) {
  .content_card--help {
    width: 85%;
  }
}

.border__radius--8px {
  border-radius: 8px !important;
}

.border__button {
  border-bottom: 2px solid var(--color-white) !important;
}

.stats__display--flex {
  display: flex;
  flex-direction: column;
}

.stats__container {
  padding: 33px 46px !important;
  border-radius: 8px !important;
}

.stats__pie--heigth {
  height: 600px !important;
}

.stats__green--card {
  background-color: #61C177 !important;
  color: var(--color-white) !important;
}

.stats__text--card {
  font-family: "Source Sans Pro" !important;
  font-size: 2.188rem !important;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
  line-height: 41px !important;
}

.logo__mission {
  height: 65px !important;
}

.home__mission--title {
  margin-top: 150px !important;
  font-family: "Source Sans Pro" !important;
  color: var(--color-white) !important;
  font-size: 3.06rem !important;
  font-weight: bold !important;
  letter-spacing: 0 !important;
  line-height: 45.11px !important;
}

.home__mission--text {
  margin-top: 17px !important;
  color: var(--color-white) !important;
  font-family: "Source Sans Pro" !important;
  font-size: 1.5rem !important;
  font-weight: 600;
  letter-spacing: 0 !important;
  line-height: 27.74px !important;
}

.dropZone__color--grey {
  color: var(--color-grey-text) !important;
}

.dropZone__color--white {
  color: var(--color-white) !important;
}

.dropZone__image--text {
  white-space: pre-line;
}

.breadcrumbs__margin {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.breadcrumbs__link--color {
  color: var(--color-grey-text) !important;
  font-family: "Source Sans Pro" !important;
  font-size: 0.813rem !important;
  letter-spacing: 0 !important;
  line-height: 15px !important;
}

.badges__preview {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

div#contact-content th,
div#contact-content caption {
  display: none;
}


.ol-map {
  min-width: 600px;
  min-height: 500px;
  margin: 50px;
  height: 500px;
  width: "100%";
}

@media only screen and (max-width: 960px) {
  .stats__container {
    padding: 33px 0px !important;
  }
}

.apexcharts-datalabels.legend-mouseover-inactive {
  opacity: 1 !important;
}

.snackbar__message {
  margin-top: 65px !important;
}

@media only screen and (min-width: 600px) {
  .snackbar__message {
    margin-left: 20px;
  }
}

.hidden__title {
  display: none;
}