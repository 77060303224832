.users__container {
  padding-top: 6rem !important;
  padding-bottom: 4rem;
}

.user__grid--margin-bottom {
  margin-bottom: 2rem !important;
}

.user__grid--margin {
  margin: 22px !important;
  margin-inline: 5px !important;
}

.user__grid--margin-top {
  margin-top: 25px !important;
}

.users--add--icon--color {
  color: var(--color-white) !important;
}

.users__form--button--container {
  padding-top: 2rem !important;
}

.users--correctPW--icon--color {
  color: var(--color-darken-green) !important;
}

.users--wrongPW--icon--color {
  color: var(--color-red) !important;
}