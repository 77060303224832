.accordion__style {
  background-color: #FFFFFF!important;
  color: var(--color-black) !important;
  margin-bottom: 15px !important;
}

.adminDrawer__icon--align-accordion {
  margin-right: 18px !important;
  display: flex !important;
  align-items: center;
}

.adminDrawer__typography--title {
  font-weight: 600 !important;
}

.adminDrawer__typography--subtitle {
  font-size: 1rem !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  letter-spacing: 0.00938em !important;
  width: 100% !important;
  display: flex !important;
  align-items: center;
  justify-content: flex-start !important;
  align-content: flex-start !important;
  word-spacing: normal !important;
  text-align: left !important;
  word-break: break-word !important;
}

.adminDrawer__menu {
  color: var(--color-black) !important;
  font-family: "Roboto" !important;
  font-size: 1.063rem !important;
  height: 40px !important;
  letter-spacing: 0 !important;
  line-height: 20px !important;
  margin-bottom: 15px !important;
}

.adminDrawer__submenu {
  color: var(--color-black)!important;
  margin-left: 26px !important;
  font-family: "Roboto" !important;
  font-size: 1.063rem !important;
  height: 40px !important;
  letter-spacing: 0 !important;
  line-height: 20px !important;
}

.adminDrawer__accordion--selected {
  background-color: var(--color-green) !important;
  color: var(--color-white) !important;
  font-weight: 900;
  margin-bottom: 15px !important;
}

.adminDrawer__submenu--selected {
  color: var(--color-darken-green) !important;
}

.adminDrawer__selected--menu {
  background-color: var(--color-lighten-green) !important;
  color: var(--color-darken-green) !important;
  font-weight: 700 !important;
}

.adminDrawer__container {
  margin-top: 27px;
  margin-bottom: 100px;
}

.adminDrawer--icon-color {
  color: var(--color-darken-green) !important;
}

.adminDrawer__icon--color--selected {
  color: var(--color-darken-green) !important
}