.groups__container {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.groups__add--icon--color {
  color: var(--color-white) !important;
}

.groups__typography {
  height: 100%;
  width: 100%;
  display: grid;
  align-content: center;
  font-weight: bold !important;
  font-size: large !important;
}

.groups__checkbox--container {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 4px;
  margin-bottom: 4px;
}

.group__grid--margin {
  margin: 22px !important;
  margin-inline: 5px !important;
}

.group__grid--margin-bottom {
  margin-bottom: 2rem !important;
}