.login__container {
  width: 464px !important;
  padding: 19px 21px !important;
  border-radius: 30px !important;
}

.login__container--SAML {
  width: 640px;
  padding: 19px;
  justify-content: center;
  min-height: 100vh;
}

.login__card--contents {
  margin-left: 50px;
}

.login__science--id--img {
  width: 100%;
  height: auto;
}

.login__scienceid--title--img {
  width: 30% !important;
  height: auto;
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.login__scienceid--box--img {
  margin-top: 4%;
  margin-left: 10%;
  padding-right: 5px;
  width: 10% !important;
  height: auto;
}

.login__science--id--button {
  width: 60%;
}

.login__cienciaID--help {
  margin-top: 100px !important;
  padding-top: 10px;
  color: #7698A3 !important;
}

.login__orcid--box--img {
  margin-left: 22%;
  padding-right: 10px;
  width: 9% !important;
  height: auto;
}

.login__authgov--grid {
  margin-top: 50px;
}

.login__authgov--img {
  width: 60%;
  height: auto;
}

.login__authgov--text {
  color: #40798C;
  margin-top: 2px !important;
}

.login__authgov--icon {
  color: #C0D8E8 !important;
}

.login__captcha--justify--right {
  display: grid !important;
  justify-content: right !important;
}

.login__container--center {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.login__title {
  display: flex;
  justify-content: center;
  margin-top: 22px !important;
  color: var(--color-black) !important;
  font-family: "Source Sans Pro" !important;
  font-size: 1.75rem !important;
  letter-spacing: 0 !important;
  line-height: 34px !important;
}

.login__required--error {
  display: flex;
  justify-content: center;
  margin-top: 22px !important;
}

.login__textfield--padding {
  padding-inline: 31px !important;
}

.login__textfield--margin-top {
  margin-top: 19px !important;
}

.login__textfield--margin-bottom {
  margin-bottom: 19px !important;
}

.login__div--flex-column {
  display: flex !important;
  flex-direction: column !important;
}

.login__div--flex-between {
  display: flex !important;
  justify-content: space-between !important;
}

.login__remember--me-text {
  color: var(--color-black) !important;
  font-family: "Source Sans Pro" !important;
  font-size: 0.875rem !important;
  letter-spacing: 0 !important;
  line-height: 16px !important;
  margin-top: -10px !important;
}

.login__checkbox-color {
  color: var(--color-link) !important;
}

.login__fullwidht {
  width: 100%;
  background-color: var(--color-darken-green) !important;
  color: var(--color-white) !important;
  font-weight: 500 !important;
  font-size: 1.125rem !important;
  letter-spacing: 0 !important;
  border-radius: 20px !important;
  text-transform: none !important;
  padding-inline: 23px !important;
}

.login__cienciaID--box {
  width: 100%;
  height: 160px !important;
  background-color: var(--color-green-chip) !important;
  border-radius: 15px !important;
  display: flex !important;
  justify-content: center !important;
  align-content: center !important;
  margin-top: 38px !important;
  align-items: center !important;
  flex-direction: column !important;
}

.login__cienciaID--box:hover {
  opacity: 0.8 !important;
}

.login__cienciaID--minibox {
  width: 344px;
  height: 58px !important;
  background-color: rgba(198, 214, 52, 0.2) !important;
  border-radius: 4px !important;
  display: flex !important;
  justify-content: center !important;
  align-content: center !important;
  margin-top: 38px !important;
  align-items: center !important;
  flex-direction: column !important;
  color: #115D35 !important;
}

.login__orcID--minibox {
  width: 344px;
  height: 58px !important;
  background-color: rgba(198, 214, 52, 0.2) !important;
  border-radius: 4px !important;
  display: flex !important;
  justify-content: center !important;
  align-content: center !important;
  margin-top: 38px !important;
  align-items: center !important;
  flex-direction: column !important;
  color: #799613 !important;
}

.login__cienciaID--minibox:hover {
  opacity: 0.8 !important;
}


.login__institutional--box {
  width: 344px;
  height: 58px !important;
  background-color: rgba(84, 132, 171, 0.1) !important;
  border-radius: 4px !important;
  margin-top: 38px !important;
  color: #5484AB !important;
}

.login__institutional--box:hover {
  opacity: 0.8 !important;
}

.login__institutional--icon {
  width: 75%;
}

.login__contents {
  margin-top: 5px !important;
  text-align: center !important;
}

.login__with--text {
  text-transform: uppercase;
  opacity: 0.8 !important;
  font-family: "Source Sans Pro" !important;
  font-size: 0.989rem !important;
  letter-spacing: 0 !important;
  line-height: 19px !important;
}

.login__cmd--help {
  color: #7698A3 !important;
}

.login__cmd--grid {
  margin-top: 10px;
}