.repositoryform__image--size {
  max-width: 100% !important;
  min-height: 250px;
  background-position: center center;
  background-repeat: no-repeat;
}

.repositoryform__buttons--margins {
  /* margin-top: -10px !important; */
  margin-bottom: 30px !important;
  margin-left: 18px !important;
}

.repositoryform__terms--margins {
  margin-top: -10px !important;
  margin-left: 25px !important;
  margin-bottom: 30px !important;
}

.repositoryimport__terms--margins {
  margin-top: 35px !important;
  margin-left: 8px !important;
}