.controlledVocabulary__container {
  padding-top: 4rem;
  padding-bottom: 4rem;
  margin-right: 6.16% !important;
  margin-left: 5.55% !important;
}

.controlledVocabulary__accordion--padding {
  margin-bottom: 5px;
}

.controlledVocabulary__grid--margin {
  margin: 22px !important;
  margin-inline: 5px !important;
}

.controlledVocabulary__grid--margin-bottom {
  margin-bottom: 2rem !important;
}

.controlledVocabulary__grid--margin-top {
  margin-top: 25px !important;
}

.controlledVocabulary__form--badge--size {
  width: 32px !important;
  height: 32px !important;
  margin-right: 0.1rem !important;
  margin-top: 2px;
}

.controlledVocabulary__form--dropzone--size {
  min-height: 100px;
}

.controlledVocabulary__badge--card--cardmedia {
  height:200px;
  max-width: 100%;
}

.controlledVocabulary__image {
  height: 100% !important;
  width: 100% !important;
}
