.repository__card--cardmedia {
  max-width: 100%;
  display: block;
}

.repository__container {
  padding-top: 4rem;
  padding-bottom: 5rem;
}

.repository__badge--size {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}