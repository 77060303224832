.home__highlights--card {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 8px !important;

}

.highlights__typography--white {
    color: var(--color-white);
}

.highlights__subtitle {
    color: rgba(255, 255, 255, 0.8);
}

.highlights__repository--spacing {
    line-height: 24px !important;
    margin-bottom: 20px !important;
}

.highlights__title--spacing {
    line-height: 35px !important;
    padding-bottom: 10px !important;
}

.highlights__typography--bold {
    font-size: 5000px;
}

.highlights__card--blue {
    background-color: var(--color-blue-nav) !important;
}

.highlights__card--green {
    background-color: var(--color-darken-green) !important;
}

.highlights__card--actions {
    margin-top: 10px;
}

.highlights_card--typography {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.highlights__button--view {
    font-weight: 500 !important;
    font-size: 1rem !important;
    letter-spacing: 0 !important;
    border-radius: 20px !important;
    margin-left: 10px !important;
    margin-right: 18px !important;
    margin-bottom: 10px !important;
    text-transform: none !important;
    padding-inline: 23px !important;
    background-color: var(--color-white) !important;
    color: var(--color-darken-green) !important;
}

.highlights__subtitle--margin {
    margin-left: 18px !important;
    padding-right: 0.5em !important;
}

.highlights__date--margin {
    margin-top: 1em !important;
}

.highlight__date {
    font-size: 0.9em !important;
}


.highlight__cardAction {
    display: inline-block;
    position: relative;
    color: rgba(255, 255, 255, 1);
    font-size: 200%;
    font-family: monospace;
    cursor: pointer;
    overflow: hidden;
    user-select: none;
    height: 100%;
}

.highlight__cardAction:hover {
    transition: 200ms ease-in;
    background-color: rgba(0, 0, 0, 0.1);
}

.highlight__cardAction::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.1);
    width: 0;
    height: 0;
    margin-left: 0;
    margin-top: 0;
    border-radius: 100%;

    transition:
        width 500ms ease-in-out,
        height 500ms ease-in-out,
        margin-left 500ms ease-in-out,
        margin-top 500ms ease-in-out;
}

.highlight__cardAction:active::after {
    width: 1000px;
    height: 1000px;
    margin-left: -250px;
    margin-top: -250px;
}