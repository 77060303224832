
.register__container {
  min-width: 40vw !important;
  padding-left: 25vw;
  padding-top: 8rem !important;
}

.register__input {
  height: 75% !important;
  border-radius: 0px !important;
}

.register__select--input {
  width: 100% !important;
  height: 75% !important;
  border-radius: 0px !important;
}

.register__select--input--no--border--radius {
  height: 100%;
  border-radius: 0px !important;
}

.register__padding--top{
  padding-top: 2vh;
}

.register__grid--container--input {
  padding-top: 3vh;
}

.register__error {
  margin-top: 0% !important;
}

.register__modal {
  position: absolute;
  background-color: var(--color-white);
  border: 2px solid var(--color-black);
  top: 10%;
  left: 5%;
  right: 5%;
  overflow: scroll;
  height:  80%;
  display: block;
}


.register_organization--dialog--button--end{
  justify-content: flex-end;
}

.register_organization--form--padding{
  padding-top: 5rem;
}

.register__container {
  padding-top: 4rem !important;
  padding-bottom: 2rem !important;
}

.captcha__justify--right {
  margin-right: 4rem !important;
  display: grid !important;
  justify-content: right;
}

.register__box--style {
  border-radius: 8px;
  padding-inline: 28px;
  margin-top: 12px;
  margin-bottom: 4px;
  padding-bottom: 4px;
  background-color: var(--color-lighten-green) !important;
}

.register__typography--top {
  width: 13%;
  padding-left: 4px;
  margin-top: -1rem !important;
  margin-left: 10px !important;
  background:var(--color--white);
}