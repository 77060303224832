.MuiDialog-root {
  z-index: 9800 !important;
}

.MuiPopover-root {
  z-index: 9999 !important;
}

.MuiInputBase-root {
  border-radius: 8px !important;
  background-color: var(--color-white) !important;
}

.MuiTablePagination-root[role="repositoriesPage"]>.MuiToolbar-root>.MuiInputBase-root {
  border-radius: 8px !important;
  background-color: white !important;
}

.MuiToolbar-regular {
  min-height: 62px !important;
}

.MuiButton-root {
  background-color: var(--color-blue-nav);
  color: var(--color-white);
  font-weight: 600;
  border-radius: 0px;
}

.MuiButton-root:hover[role="navBar"] {
  opacity: 0.66 !important;
}

/* Hide scrollbar DRAWER for IE, Edge and Firefox */

.MuiDrawer-paper {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1) !important;
}

/* Hide scrollbar DRAWER for Chrome, Safari and Opera */

.MuiDrawer-paper::-webkit-scrollbar {
  display: none;
}

.MuiCardHeader-root {
  background-color: var(--color-blue-nav) !important;
  color: var(--color-white) !important;
  border-radius: 0px !important;
}

.MuiAppBar-colorPrimary {
  background-color: var(--color-blue-nav) !important;
  color: var(--color-white) !important;
}

.MuiDrawer-paperAnchorDockedLeft {
  width: var(--navbar-width);
  background-color: var(--color-white) !important;
}

.MuiListItem-root {
  color: inherit;
  height: 66px;
  font-size: 0.938rem !important;
}

.MuiListItem-root[role="option"] {
  font-family: "Source Sans Pro" !important;
  font-size: 0.875rem !important;
  letter-spacing: 0 !important;
  line-height: 16px !important;
  background-color: var(--color-white) !important;
  color: var(--color-black) !important;
  font-weight: 600;
  height: 40px;
}

.MuiTableContainer-root {
  border-radius: 8px;
}

.MuiAccordionDetails-root {
  background-color: var(--color-lighten-green2);
}

.MuiAccordionDetails-root[role="repositories"] {
  background-color: transparent !important;
}

div#menu-configurations-general-defaultLanguage .MuiListItem-button {
  text-transform: none !important;
}

.MuiListItem-button {
  text-transform: none !important;
}

.MuiListItem-button:hover {
  background-color: transparent !important;
}

.MuiList-padding[role="adminMenu"] {
  width: 100% !important;
}

/* .MuiListItem-root:focus {
  background-color: var(--color-green) !important;
  color: var(--color-white) !important;
  font-weight: 900;
} */

.MuiListItem-root[role="selectMenu"] {
  background-color: var(--color-white) !important;
  color: var(--color-black) !important;
  font-weight: 600;
}

.MuiListItem-root[role="selectMenu"]:hover {
  opacity: 0.8;
}

.MuiListItem-root[role="adminMenu"]:focus {
  color: var(--color-darken-green) !important;
}

.MuiAccordion-root {
  color: var(--color-blue);
}

.MuiAccordionSummary-root {
  height: 40px !important;
  min-height: unset !important;
}

.MuiAccordionSummary-expandIcon {
  color: inherit !important;
}

.MuiAccordion-root:before {
  background-color: var(--color-white) !important;
}

.Mui-expanded {
  /* background-color: var(--color-green) !important;
  color: var(--color-white) !important;
  font-weight: 900; */
  margin-block: 0px !important;
}

.MuiCollapse-wrapperInner {
  background-color: var(--color-white) !important;
}

.MuiLinearProgress-root {
  height: 10px !important;
}

.MuiLinearProgress-bar {
  background-color: var(--color-green) !important;
}

.MuiLinearProgress-colorPrimary {
  background-color: #dddddd !important;
}

.MuiTypography-root {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.MuiTabs-root {
  background-color: transparent !important;
  margin-inline: -3px !important;
}

.MuiTab-root {
  background-color: transparent;
  height: 44px;
  font-family: "Source Sans Pro" !important;
  font-weight: 700 !important;
  font-size: 0.875rem !important;
  letter-spacing: 0 !important;
  line-height: 16px !important;
}

.MuiTab-textColorPrimary {
  background: linear-gradient(0deg, var(--color-green-tabs-shade) 7%, var(--color-green-tabs) 12%);
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
  margin-inline: 3px !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  background: var(--color-white) !important;
}

.Mui-selected>.MuiTab-wrapper {
  color: var(--color-blue-nav) !important;
}

.MuiTab-wrapper {
  color: var(--color-disabled-tab-text);
  flex-direction: row !important;
  justify-content: flex-start !important;
  padding-left: 1.3em !important
}

.MuiTabs-indicator {
  color: transparent !important;
  background-color: transparent !important;
}

.MuiTabScrollButton-root.Mui-disabled {
  background-color: var(--color-green) !important;
  color: var(--color-green) !important;
  opacity: 0.4 !important;
}

.MuiTabScrollButton-root {
  background-color: var(--color-darken-green) !important;
  color: var(--color-white) !important;
  padding-left: 30px;
  font-size: 0.875rem;
  font-weight: 900;
}

.MuiBox-root-43 {
  padding-inline: 28px !important;
  padding-block: 20px !important;
}

.MuiBox-root {
  padding-inline: 28px !important;
  padding-block: 20px !important;
}

.MuiButton-root[role="adminCards"] {
  background-color: var(--color-darken-green) !important;
  border-radius: 20px !important;
  color: var(--color-white) !important;
  font-size: 1.125rem !important;
  text-transform: none;
  padding-inline: 23px;
  letter-spacing: 0;
}

.MuiButtonBase-root[role="selectMenu"] {
  height: 40px !important;
}

.MuiInputBase-root {
  border-style: none !important;
  border-width: 0px !important;
}

.MuiTableSortLabel-icon {
  color: inherit !important;
}

.MuiTableCell-root {
  text-overflow: ellipsis !important;
  white-space: nowrap;
  overflow: hidden;
  border-bottom-width: 2px !important;
  border-color: var(--color-background) !important;
}

textarea {
  min-height: 138px !important;
}

.MuiFormLabel-root {
  color: var(--color-grey-outline) !important;
  font-size: 1rem;
  letter-spacing: 0;
}

.MuiOutlinedInput-root {
  border-color: var(--color-grey-outline) !important;
}

.MuiOutlinedInput-root[role="RepositoriesSort"] {
  border-color: var(--color-darken-green) !important;
}

.MuiOutlinedInput-root[role="searchInput"] {
  border-color: var(--color-white) !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: inherit !important;
  border-radius: 3px !important;
}

.MuiAutocomplete-tag {
  color: var(--color-grey-text) !important;
  background-color: var(--color-green-chip) !important;
}

.MuiInputBase-input {
  color: var(--color-grey-text) !important;
}

.MuiInputBase-input[role="RepositoriesSort"] {
  color: var(--color-white) !important;
  background-color: var(--color-blue-nav) !important;
}

.MuiTableRow-root.MuiTableRow-hover:hover {
  cursor: pointer !important;
  background-color: var(--color-lighten-green) !important;
}

.MuiDropzoneArea-root {
  height: 100% !important;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  color: var(--color-grey-text) !important;
  font-size: 1.125rem !important;
}

.MuiSwitch-colorSecondary.Mui-checked {
  color: var(--color-darken-green) !important;
}

.MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
  background-color: var(--color-darken-green) !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: var(--color-darken-green) !important;
}

.MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock {
  white-space: nowrap;
  width: 11rem;
}

div#highContrast .MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock {
  width: unset;
}

.RequestPage .MuiChip-root.Mui-disabled {
  opacity: 0.75;
}

.RequestPage .Mui-disabled .MuiAutocomplete-endAdornment,
.RequestPage .Mui-disabled svg.MuiSvgIcon-root.MuiChip-deleteIcon,
.RequestPage svg.MuiSvgIcon-root.MuiSelect-icon.arrowicon__color.Mui-disabled {
  display: none;
}

.RequestPage .repositoryform__image--size {
  max-height: 14rem;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: var(--color-darken-green) !important;
}

.MuiCheckbox-colorSecondary:hover {
  background-color: var(--color-lighten-green2) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.MuiSelect-select:focus {
  background-color: transparent !important;
}


.MuiAlert-root {
  padding: 30px 50px !important;
  font-size: 1rem !important;
  align-items: center !important;
}

.MuiAlert-icon {
  font-size: 3rem !important;
}