.home__div--padding-top {
  padding-top: 10rem;
}

.home__warning--icon-size {
  font-size: 7rem !important;
  color: #ad0f0f;
}

.error__justify--center {
  display: flex;
  justify-content: center;
}

.contact__container {
  padding-top: 4rem !important;
  padding-bottom: 2rem !important;
}

.contact__font--size {
  font-size: larger !important;
  font-weight: 600;
  display: grid;
  justify-content: center;
} 

.contact__title--center{
  display: grid !important;
  justify-content: center !important;
}

.home__card1--backgroundColor{
  width: 100% !important;
  background-color: var(--color-blue-nav) !important;
}

.home__card2--backgroundColor{
  width: 100% !important;
  background-color: var(--color-darken-green) !important;
}

.home__card3--backgroundColor{
  width: 100% !important;
  background-color: var(--color-blue) !important;
}

.home__card4--backgroundColor{
  width: 100% !important;
  background-color: var(--color-red) !important;
} 

.home__card1--backgroundColor:hover {
  cursor: pointer !important;
  opacity: 0.8 !important;
 }

.home__card2--backgroundColor:hover {
  cursor: pointer !important;
  opacity: 0.8 !important;
 }

.home__card3--backgroundColor:hover {
  cursor: pointer !important;
  opacity: 0.8 !important;
 }

.home__card4--backgroundColor:hover {
 cursor: pointer !important;
 opacity: 0.8 !important;
}

.home__card--icon--padding{
  margin-left: 15px !important;
}

.home__card--typography {
  color: var(--color-white) !important;
  font-weight: 900 !important;
  opacity: 0.9 !important;
} 

.home__cardContent--display{
  display: flex !important;
  align-items: center !important;
  justify-items: center !important;
  justify-content: space-between !important;
  height: 100% !important;
  width: 100% !important;
}

.home__card--icon--size{
  width: 55px !important;
  height: 40px !important;
  opacity: 0.5 !important;
  color: var(--color-white); 
}

.home--icon--flex--end {
  display: flex !important;
  justify-content: flex-end !important;
}