body {
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  ;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  font-family: 'Roboto', 'Source Sans Pro';
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-body {
  padding-top: var(--navbar-height);
  background-color: var(--color-background);
  min-height: 100vh;
}

.App-body-admin {
  padding-top: var(--navbar-height);
  background-color: var(--color-background);
  flex-grow: 1 !important;
  z-index: 1;
  margin-left: var(--navbar-width);
  min-height: 100vh;
}

.footer {
  z-index: 1300 !important;
  width: 100%;
  background-color: var(--color-darken-green) !important;
  display: flex !important;
  position: relative;
  bottom: 0;
  align-items: center;
  justify-content: space-between;
  color: var(--color-white);
  min-height: 100px !important;
  padding: 32px !important;
}

@media screen {
  :root {
    --navbar-height: 62px;
    --navbar-width: 304px;
    --color-blue-nav: #00ADC5;
    --color-blue-tab: #7CD9E3;
    --color-blue-table-header: #02B6CC;
    --color-lighten-blue: rgba(2, 182, 204, 0.22);
    --color-blue: #20B6CC;
    --color-link: #02B6CC;
    --color-green: #80CE92;
    --color-green-chip: rgba(54, 176, 83, 0.14);
    --color-lighten-green: rgba(54, 176, 83, 0.2);
    --color-green-tabs: rgba(96, 192, 118, 0.25);
    --color-green-tabs-shade: rgba(54, 176, 83, 0.25);
    --color-lighten-green2: rgba(108, 200, 130, 0.07);
    --color-darken-green: #30A849;
    --color-grey-outline: #D4DFE0;
    --color-grey-text: #6F6F6F;
    --color-white: #ffffff;
    --color-red: #B84547;
    --color-background: #F6F6F6;
    --color-black: #202020;
    --color-request-edit: #f7ae26;
    --color-request-remove: #177200;
    --color-orange: #C55700;
    --color-request-add: #ee3e3e;
    --color-lighten-red: rgba(184, 69, 71, 0.10);
  }
}

@media only screen and (max-width: 1280px) {
  .App-body-admin {
    margin-left: 0 !important;
  }
  .homeFrontOffice__div--padding-top {
    min-height: auto !important;
  }
  .about__content--card {
    width: 80% !important;
    max-width: 907px !important;
  }
}

.App-display {
  display: flex;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282C34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(0.625rem + 2vmin);
  color: var(--color-white);
}

.App-link {
  color: #61DAFB;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button__transparent--text-color {
  background-color: transparent !important;
  color: var(--color-black) !important;
}

.footer__right {
  display: flex !important;
  justify-content: flex-end !important;
}

.footer__logos {
  height: 32px;
  padding: 5px;
}

.footer__left {
  display: flex !important;
  justify-content: flex-start !important;
}

.footer__block--margin {
  margin-right: 85px !important;
}

a {
  color: var(--color-link) !important;
  font-family: "Source Sans Pro";
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 19px;
  text-decoration: none;
}

.backgroundImage {
  background: url(../utils/assets/FUndo3.svg) bottom center no-repeat;
  background-color: var(--color-background) !important;
}

.homeImage {
  width: 100% !important;
  max-height: 425px !important;
}

.mission__position {
  position: absolute;
  color: var(--color-white) !important;
}

.image__container {
  height: 425px !important;
  background: url(../utils/assets/HomeImage1.jpg) !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  width: 100% !important;
}

.text-block {
  color: white;
  font-size: 1.875rem !important;
}

.margin__top--32px {
  margin-top: 32px !important;
}

@media only screen and (max-width: 467px) {
  .MuiAppBar-positionFixed {
    position: absolute !important;
  }
  .footer__block--margin {
    margin-right: 0px !important;
  }
  .footer__logos {
    height: 30px !important;
  }
  .footer__right {
    display: unset !important;
  }
  .repositories__body--container {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .searchbar__advanced {
    padding: 17px 3px !important;
  }
}

@media only screen and (max-width: 960px) {
  .MuiGrid-grid-xs-12 {
    max-width: 33% !important;
  }
  .stats__container {
    padding: 33px 0px !important;
  }
}

.login__container--center .MuiButton-label {
  display: unset;
}

.MuiTypography-root.login__with--text.MuiTypography-body1.MuiTypography-gutterBottom {
  padding-bottom: 0.5rem;
}

h2.MuiTypography-root.home__mission--text.MuiTypography-body1 {
  background-image: linear-gradient(to top, rgb(0 0 0 / 0%), transparent)
}

div#contact-content img {
  width: 18rem;
}

div#contact-content svg {
  margin-bottom: -7px;
  margin-right: 13px;
}

div#contact-card {
  border-radius: 20px !important;
}

div#contact-content td#img-contacts {
  background: url(../utils/assets/logo_FCT.svg)!important;
  background-size: cover;
  background-position: 50%!important;
  height: 8rem;
  background-repeat: no-repeat !important;
}

@media only screen and (max-width: 968px) {
  div#contact-content td#img-contacts {
    background-size: 100% 100% !important;
  }
  div#contact-content td:last-child, div#contact-content td:first-child {
    padding-right: 0 !important;
    padding-left: 0px !important;
  }
  div#contact-content td {
    display: inline-block;
    width: 100% !important;
    text-align: center;
  }
}
.MuiPaper-elevation4.appNavBar__width--user-menu.appNavBar__user--container{
    color: #fff;
}