.request__grid--margin {
  margin-top: 10px !important;
}

.request__identifier--typography {
  height: 100%;
  width: 100%;
  display: grid;
  align-content: center;
  font-weight: bold !important;
  font-size: large !important;
}

.request__chip--flex {
  background-color: var(--color-blue-tab) !important;
  color: #fff !important;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  margin-right: 4px;
  margin-bottom: 4px;
  font-family: "Source Sans Pro" !important;
  font-size: 1rem !important;
  letter-spacing: 0 !important;
  line-height: 19px !important;
  overflow: hidden;
  min-height: 2rem !important;
  height: min-content !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.request__color--yellow {
  color: var(--color-request-edit) !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  display: grid;
  align-content: center;
  font-weight: bold !important;
  font-size: large !important;
}

.request__color--green {
  color: var(--color-request-add);
  margin-left: 10px !important;
  margin-right: 10px !important;
  display: grid;
  align-content: center;
  justify-content: center;
  font-weight: bold !important;
  font-size: large !important;
}

.request__color--red {
  color: var(--color-request-remove);
  margin-left: 10px !important;
  margin-right: 10px !important;
  display: grid;
  align-content: center;
  justify-content: center;
  font-weight: bold !important;
  font-size: large !important;
}

.request__grid--margin-bottom {
  margin-bottom: 7px !important;
}

.request__button--color {
  color: var(--color-white) !important;
}

.request__align--right {
  margin-top: 10px !important;
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
}

.workflow__circle {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 6px !important;
}

.workflow__circle--changed {
  background-color: var(--color-orange) !important;
}

.workflow__circle--added {
  background-color: var(--color-darken-green) !important;
}

.workflow__circle--not-changed {
  background-color: var(--color-grey-text) !important;
}

.request__flex--text {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.request__align--text {
  margin-inline: 2px !important;
  padding-bottom: 6px !important;
  align-items: center;
}

.request__color--update>.MuiOutlinedInput-root>.MuiInputBase-input {
  color: var(--color-orange) !important;
}

.request__color--create>.MuiOutlinedInput-root>.MuiInputBase-input {
  color: var(--color-darken-green) !important;
}

.request__color--update>.MuiFormControl-root>.MuiOutlinedInput-root>.MuiInputBase-input {
  color: var(--color-orange) !important;
}

.request__color--create>.MuiFormControl-root>.MuiOutlinedInput-root>.MuiInputBase-input {
  color: var(--color-darken-green) !important;
}

.request__color--update>.MuiFormControl-root>.MuiOutlinedInput-root>.MuiAutocomplete-tag {
  color: var(--color-orange) !important;
}

.request__color--create>.MuiFormControl-root>.MuiOutlinedInput-root>.MuiAutocomplete-tag {
  color: var(--color-darken-green) !important;
}