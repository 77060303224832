.notifications__container {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.notifications__word--wrap {
  word-wrap:break-word;
}

.notifications__grid--margin-bottom {
  margin-bottom: 2rem !important;
}

.notifications__grid--margin {
  margin: 22px !important;
  margin-inline: 5px !important;
}

.notifications__grid--margin-top {
  margin-top: 25px !important;
}
