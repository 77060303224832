.searchbar__border-radius {
  border-radius: 0px !important;
  background-color: var(--color-white) !important;
}

.searchbar__grid--margin {
  margin-bottom: 5px;
}

.searchbar__input--padding-right {
  padding-right: 2px !important;
}

.searchbar__input--padding-left {
  padding-left: 2px !important;
}

.searchbar__button {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0px !important;
}

.searchbar__button--color {
  background-color: var(--color-darken-green) !important;
  color: var(--color-white) !important;
}

.searchbar__button--color:hover {
  opacity: 0.9 !important;
}

.searchbar__button--color-text {
  color: var(--color-black) !important;
}

.searchbar__input--font-size {
  font-size: 0.75rem !important;
  height: 110% !important;
}

.searchbar__title-size {
  font-weight: 900 !important;
  color: var(--color-black);
}

.searchbar__box--size {
  width: 100%;
}

.searchbar__box--width {
  max-width: 912px !important;
}

.searchbar__margin--bottom-52px {
  margin-bottom: 52px !important;
}

.searchbar__button--advanced {
  background-color: transparent !important;
  color: var(--color-link) !important;
  text-transform: lowercase !important;
}

.searchbar__advanced--color {
  background-color: var(--color-blue-nav) !important;
  color: var(--color-white) !important;
}

.searchbar__advanced {
  border-radius: 8px !important;
  background-color: var(--color-green-tabs) !important;
  padding: 17px 22px;
  margin-top: 24px !important;
}

.searchbar__margin--top-24px {
  margin-top: 24px !important;
}

.searchbar__margin--bottom-82px {
  margin-bottom: 82px !important;
}

.searchbar__button--add {
  font-family: "Roboto" !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 19px !important;
  color: var(--color-grey-text) !important;
  text-transform: none !important;
}

.searchbar__advanced--button {
  color: var(--color-grey-text) !important;
  min-width: 0 !important;
}

.searchbar__advanced--grid {
  flex-basis: 0 !important;
}

.searchbar__clear--button {
  min-width: 0 !important;
}

.searchbar__container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  flex-direction: column;
}