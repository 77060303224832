.organizations__container {
  padding-top: 4rem;
  padding-bottom: 5rem;
}

.organization__grid--margin {
  margin: 22px !important;
  margin-inline: 5px !important;
}

.organization__grid--margin-bottom {
  margin-bottom: 2rem !important;
}

.organization--add--icon--color {
  color: var(--color-white) !important;
}
